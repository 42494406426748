import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Store } from '@ngrx/store'
import { AppState } from '@boa/app/app.store'
import { of } from 'rxjs'
import { withLatestFrom, map, switchMap, catchError } from 'rxjs/operators'

import * as FleetVehicleTypesActions from './fleet-vehicle-types.actions'
import { FleetVehicleInfoService } from '@boa/app/_shared/api/fleet-vehicle-info.api'

@Injectable()
export class FleetVehicleTypesEffects {
  find$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FleetVehicleTypesActions.ActionTypes.FIND_FLEET_VEHICLE_INFO),
      withLatestFrom(
        this.store,
        (_: FleetVehicleTypesActions.FindFleetVehicleTypesAction, state) =>
          state.fleetVehicleTypes.vehicles
      ),
      map((fleetVehicleTypes) => fleetVehicleTypes.length > 0),
      switchMap((isFleetVehicleInfoAlreadyFound) => {
        if (isFleetVehicleInfoAlreadyFound) {
          return of(new FleetVehicleTypesActions.FleetVehicleInfoAlreadyFound())
        }

        return this.fleetVehicleInfoService.find({ limit: 0, skip: 0 }).pipe(
          map(
            (res) =>
              new FleetVehicleTypesActions.FindFleetVehicleInfoSuccessAction(
                res
              )
          ),
          catchError((err) =>
            of(
              new FleetVehicleTypesActions.FindFleetVehicleInfoErrorAction(err)
            )
          )
        )
      })
    )
  )

  constructor(
    private actions$: Actions,
    private fleetVehicleInfoService: FleetVehicleInfoService,
    private store: Store<AppState>
  ) {}
}
