import { Injectable } from '@angular/core'

import { Store, select } from '@ngrx/store'
import { Observable } from 'rxjs'
import { filter, map, take } from 'rxjs/operators'
import { AppState } from '@boa/app/app.store'

@Injectable()
export class PermissionsResolveGuard {
  constructor(private store: Store<AppState>) {}

  resolve(): Observable<boolean> {
    // data fetching is invoked at
    // app/_shared/reducers/user/effects/init$
    return this.waitForPermissionsToLoad()
  }

  waitForPermissionsToLoad(): Observable<boolean> {
    return this.store.pipe(
      select((s) => s.user?.accessControlUserData?.permissions),
      filter((v) => !!v),
      map((v) => !!v),
      take(1)
    )
  }

  canActivate(): Observable<boolean> {
    return this.resolve()
  }

  canActivateChild(): Observable<boolean> {
    return this.resolve()
  }
}
