import { Injectable } from '@angular/core'
import { Store } from '@ngrx/store'

import { getState } from '../helpers/get-state.helper'

@Injectable()
export class ConsoleCommandsService {
  constructor(private store: Store<any>) {}

  private dumpState() {
    return getState(this.store)
  }

  bindCommands() {
    window['boa'] = {
      dumpState: () => this.dumpState(),
    }
  }
}
