import { type Routes } from '@angular/router'
import { environment } from '../environments/environment'
import { AuthGuard } from './_shared/services/auth-guard.service'
import { PermissionsResolveGuard } from './_shared/services/permissions-resolve-guard.service'
import { LayoutComponent } from './_shared/modules/main-components/layout/layout.component'
import { navigationListProvider } from './_shared/navigation-lists/navigation-list.token'
import { getAuthProtectedRoutes } from './_shared/navigation-lists/auth-protected.routes'

export const routes: Routes = [
  {
    path: 'reset-password',
    loadChildren: () => import('./reset-password/reset-password.routes'),
  },
  {
    path: 'login',
    loadComponent: () => import('./login/login.component'),
  },
  {
    path: '403',
    loadChildren: () =>
      import('./error-pages/index').then((m) => m.ErrorPagesModule),
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'home',
  },
  // locked routes
  {
    path: '',
    component: LayoutComponent,
    providers: [navigationListProvider],
    canActivate: [PermissionsResolveGuard],
    canActivateChild: [AuthGuard],
    loadChildren: () => getAuthProtectedRoutes(environment.isSaas),
  },
]
