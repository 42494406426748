import { Injectable } from '@angular/core'

import { of } from 'rxjs'
import { map, switchMap, tap, catchError } from 'rxjs/operators'

import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Store, select } from '@ngrx/store'
import { AppState } from '@boa/app/app.store'
import * as UserActions from './user.actions'

import { AccessToken } from '@boa/booster-types'

import { AlertService } from '@boa/app/_shared/services/alert.service'
import { CustomerService } from '@boa/app/_shared/api/customer.api'
import { UserService } from '@boa/app/_shared/services/user.service'

import { defer } from 'rxjs/internal/observable/defer'
import { NavigatorService } from '@boa/app/_shared/services/navigator.service'

@Injectable()
export class UserEffects {
  login$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.ActionTypes.LOGIN),
      switchMap((action: any) =>
        this.customerService.login(action.payload).pipe(
          map((res: any) => new UserActions.LoginSuccessAction(res)),
          catchError((err) => of(new UserActions.LoginErrorAction(err)))
        )
      )
    )
  )

  loginSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActions.ActionTypes.LOGIN_SUCCESS),
        map((action: UserActions.LoginSuccessAction) => action.payload),
        tap((accessToken: AccessToken) => {
          this.userService.saveSession(accessToken)
        }),
        tap(() =>
          this.store.dispatch(
            new UserActions.GetUserDataAction({
              initiatedFrom: 'LOGIN',
              selectedRegionId: this.userService.getSelectedRegionId(),
            })
          )
        )
      ),
    { dispatch: false }
  )

  getUserData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.ActionTypes.GET_USER_DATA),
      map((action: UserActions.GetUserDataAction) => action.payload),
      switchMap((payload) => {
        return this.customerService.getAdminData().pipe(
          map(
            (res: any) =>
              new UserActions.GetUserDataSuccessAction({
                ...res,
                selectedRegionId: this.userService.getSelectedRegionId(),
                initiatedFrom: payload.initiatedFrom,
              })
          ),
          catchError((err) =>
            of(
              new UserActions.GetUserDataErrorAction({
                ...err,
                initiatedFrom: payload.initiatedFrom,
              })
            )
          )
        )
      })
    )
  )

  getUserDataSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActions.ActionTypes.GET_USER_DATA_SUCCESS),
        map((action: UserActions.GetUserDataSuccessAction) => action.payload),
        tap(({ user, initiatedFrom }) => {
          this.userService.setUser(user)
          window.analytics.identify(user._id, {
            name: `${user.firstName} ${user.lastName}`,
            email: user.email,
          })
          if (initiatedFrom === 'LOGIN') {
            this.alertService.pushAlert({
              type: 'success',
              message: `Welcome back, ${user.firstName}!`,
            })
          }
          this.navigator.navigateToApp()
        })
      ),
    { dispatch: false }
  )

  getUserDataError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActions.ActionTypes.GET_USER_DATA_ERROR),
        map((action: any) => action.payload),
        tap(() => this.navigator.navigateToLogin()),
        tap((payload) =>
          this.alertService.pushAlert({
            type: 'danger',
            message:
              payload.initiatedFrom === 'LOGIN'
                ? 'Login failed!'
                : 'User not found, please login!',
          })
        )
      ),
    { dispatch: false }
  )

  logout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.ActionTypes.LOGOUT),
      tap(() => this.userService.deleteSession()),
      switchMap(() =>
        this.customerService.logout().pipe(
          map((res: any) => new UserActions.LogoutSuccessAction(res)),
          catchError((err) => of(new UserActions.LogoutErrorAction(err)))
        )
      )
    )
  )

  logoutSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActions.ActionTypes.LOGOUT_SUCCESS),
        tap(() => this.navigator.navigateToLogin())
      ),
    { dispatch: false }
  )

  init$ = createEffect(
    () =>
      defer(() => {
        const isAppLoading = this.store.pipe(select((s) => s.user.isAppLoading))

        const user = this.userService.getUser()

        if (user && isAppLoading) {
          this.store.dispatch(
            new UserActions.GetUserDataAction({
              user,
              selectedRegionId: this.userService.getSelectedRegionId(),
              initiatedFrom: 'APP',
            })
          )
        } else {
          this.navigator.navigateToLogin()
        }
        return of(undefined)
      }),
    { dispatch: false }
  )

  sendResetPasswordEmail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.ActionTypes.SEND_RESET_PASSWORD_EMAIL),
      switchMap((action: UserActions.SendResetPasswordEmailAction) =>
        this.customerService.sendResetPasswordEmail(action.payload).pipe(
          map(
            (res) => new UserActions.SendResetPasswordEmailSuccessAction(res)
          ),
          catchError((err) =>
            of(new UserActions.SendResetPasswordEmailErrorAction(err))
          )
        )
      )
    )
  )

  sendResetPasswordEmailSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActions.ActionTypes.SEND_RESET_PASSWORD_EMAIL_SUCCESS),
        tap(() => {
          this.alertService.pushAlert({
            type: 'success',
            message: 'Email sent',
          })
        })
      ),
    { dispatch: false }
  )

  setSelectedRegionId$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActions.ActionTypes.SET_SELECTED_REGION_ID),
        tap((action: UserActions.SetSelectedRegionIdAction) => {
          this.userService.setSelectedRegionId(action.payload)
        })
      ),
    { dispatch: false }
  )

  constructor(
    private actions$: Actions,
    private alertService: AlertService,
    private customerService: CustomerService,
    private navigator: NavigatorService,
    private store: Store<AppState>,
    private userService: UserService
  ) {}
}
