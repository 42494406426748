import { UrlTree, DefaultUrlSerializer, UrlSerializer } from '@angular/router'
import { Injectable } from '@angular/core'

@Injectable()
export class CustomUrlSerializer implements UrlSerializer {
  parse(url: any): UrlTree {
    const dus = new DefaultUrlSerializer()
    return dus.parse(url)
  }

  serialize(tree: UrlTree): any {
    const dus = new DefaultUrlSerializer()
    const path = dus.serialize(tree)
    // replace '+' char in the url
    return path.replace(/%2B|[+]/g, ' ')
  }
}
