import { Injectable } from '@angular/core'
import { Store } from '@ngrx/store'
import { AppState } from '@boa/app/app.store'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { of } from 'rxjs'
import {
  withLatestFrom,
  map,
  switchMap,
  catchError,
  filter,
} from 'rxjs/operators'

import * as VehicleTypesActions from './vehicle-types.actions'
import { VehicleInfoService } from '@boa/app/_shared/api/vehicle-info.api'

@Injectable()
export class VehicleTypesEffects {
  find$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VehicleTypesActions.ActionTypes.FIND_VEHICLE_INFO),
      withLatestFrom(
        this.store,
        (action: VehicleTypesActions.FindVehicleTypesAction, state) =>
          state.vehicleTypes
      ),
      filter((VehicleTypes) => VehicleTypes.makes.length === 0),
      switchMap(() => {
        return this.vehicleInfoService.findCommon().pipe(
          map(
            (res) => new VehicleTypesActions.FindVehicleInfoSuccessAction(res)
          ),
          catchError((err) =>
            of(new VehicleTypesActions.FindVehicleInfoErrorAction(err))
          )
        )
      })
    )
  )

  constructor(
    private actions$: Actions,
    private vehicleInfoService: VehicleInfoService,
    private store: Store<AppState>
  ) {}
}
