import {
  ActivatedRouteSnapshot,
  Params,
  RouterStateSnapshot,
  UrlSegment,
} from '@angular/router'
import { RouterStateSerializer } from '@ngrx/router-store'

export interface CustomRouterReducerState {
  fragment: string
  params: Params
  queryParams: Params
  url: string
  urlSegments: UrlSegment[]
}

export class CustomRouterStateSerializer
  implements RouterStateSerializer<CustomRouterReducerState>
{
  serialize(routerState: RouterStateSnapshot): CustomRouterReducerState {
    let params: Params = {}
    let route: ActivatedRouteSnapshot = routerState.root
    let urlSegments: UrlSegment[] = []

    while (route.firstChild) {
      params = { ...params, ...route.params }
      urlSegments = urlSegments.concat(route.url)
      route = route.firstChild
    }

    const {
      url,
      root: { queryParams },
    } = routerState

    const { fragment } = route

    return { fragment, params, queryParams, url, urlSegments }
  }
}
