import { Component, OnInit, HostListener } from '@angular/core'
import { Router, NavigationEnd } from '@angular/router'
import { Title } from '@angular/platform-browser'

import { ConsoleCommandsService } from './_shared/services/console-commands.service'

@Component({
  selector: 'app-root',
  template: `
    <alert></alert>
    <router-outlet></router-outlet>
  `,
  standalone: false,
})
export class AppComponent implements OnInit {
  public previousUrl: string
  constructor(
    private consoleCommandsService: ConsoleCommandsService,
    private router: Router,
    private titleService: Title
  ) {
    this.previousUrl = ''
  }

  @HostListener('window:beforeunload', ['$event'])
  beforeUnloadHander() {
    if (this.previousUrl) {
      window.analytics.track('PAGE_LEAVE', {
        url: this.previousUrl,
        onPageDuration: new Date().getTime() - window.boa.pageEnteredAt,
      })
    }
  }

  ngOnInit() {
    this.titleService.setTitle('boa')

    navigator.geolocation.getCurrentPosition((loc) => {
      const obj = {
        lat: loc.coords.latitude,
        lon: loc.coords.longitude,
      }
      window.localStorage.setItem('userGeoLocation', JSON.stringify(obj))
    })

    this.router.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd) {
        if (this.previousUrl) {
          window.analytics.track('PAGE_LEAVE', {
            url: this.previousUrl,
            onPageDuration: new Date().getTime() - window.boa.pageEnteredAt,
          })
        }
        window.boa.pageEnteredAt = new Date().getTime()
        window.analytics.track('PAGE_ENTER', { url: evt.url })
        this.previousUrl = evt.url
      }
    })

    this.consoleCommandsService.bindCommands()
  }
}
