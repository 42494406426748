import { enableProdMode } from '@angular/core'
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic'

import { AppModule } from './app/'
import { environment } from './environments/environment'
import { SEGMENT_SCRIPT } from './assets/lib/segment.script'

segment()

window.environment = {
  isSaas: environment.isSaas,
  envName: environment.envName,
}

if (environment.production) {
  enableProdMode()
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((error) => {
    console.error(error)
  })

function segment() {
  if (environment.isSegmentEnabled) {
    const script = document.createElement('script')
    script.innerHTML = SEGMENT_SCRIPT
    document.head.appendChild(script)
  } else {
    window.analytics = {
      track: (...args) => {
        console.info(args[0], args[1])
      },
      identify: (...args) => {
        console.info(args[0], args[1])
      },
    }
  }
}
