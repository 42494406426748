// Core
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { BrowserModule, Title } from '@angular/platform-browser'
import { EffectsModule } from '@ngrx/effects'
import { FormsModule } from '@angular/forms'
import { FormValidationModule } from '@boa/app/_shared/modules/ngx-validation'
import { InlineSVGModule } from 'ng-inline-svg-2'
import { isDevMode, NgModule, SecurityContext } from '@angular/core'
import { NgbModule } from '@ng-bootstrap/ng-bootstrap'
import {
  PreloadAllModules,
  RouterModule,
  UrlSerializer,
  NoPreloading,
} from '@angular/router'
import { CookieService } from 'ngx-cookie-service'
import { MarkdownModule } from 'ngx-markdown'

// State
import { provideStore, StoreModule } from '@ngrx/store'
import { metaReducers, ROOT_REDUCERS } from './app.store'
import { UserEffects } from './_shared/reducers/user/user.effects'
import { VehicleTypesEffects } from './_shared/reducers/vehicle-types/vehicle-types.effects'
import { FleetVehicleTypesEffects } from './_shared/reducers/fleet-vehicle-types/fleet-vehicle-types.effects'

// Main Components
import { MainComponentsModule } from './_shared/modules/main-components/index'
import { AppComponent } from './app.component'

const mainComponents = [AppComponent]

// Bundles
import { sharedServices } from './_shared/services'

// Routes
import { routes } from './app.routes'
import { StoreRouterConnectingModule } from '@ngrx/router-store'
import { CustomUrlSerializer } from '@boa/app/_shared/helpers/custom-url-serializer.helper'

// Pipes
import { CustomCurrencyPipe } from './_shared/pipes/custom-currency.pipe'
import { ModalsModule } from './_shared/modules/modals'

import { environment as ENV } from '@boa/app/../environments/environment'
import { CustomRouterStateSerializer } from './_shared/reducers/custom-router-state-serializer'
import { provideStoreDevtools } from '@ngrx/store-devtools'
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http'

declare global {
  interface Window {
    analytics: any
    google: any
    boa: any
    environment: {
      isSaas: boolean
      envName: string
    }
  }
}

@NgModule({
  declarations: [...mainComponents],
  imports: [
    // ng modules
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,

    // routing
    RouterModule.forRoot(routes, {
      useHash: true,
      preloadingStrategy: ENV.preloadStrategyPreloadAll
        ? PreloadAllModules
        : NoPreloading,
    }),

    // ngrx modules
    StoreModule.forRoot({}),
    EffectsModule.forRoot([
      UserEffects,
      VehicleTypesEffects,
      FleetVehicleTypesEffects,
    ]),
    StoreRouterConnectingModule.forRoot({
      serializer: CustomRouterStateSerializer,
    }),
    // 3rd party modules
    NgbModule,
    InlineSVGModule,
    FormValidationModule,
    MarkdownModule.forRoot({ sanitize: SecurityContext.NONE }),

    // boa modules
    MainComponentsModule,
    ModalsModule,
  ],
  providers: [
    Title,
    ...sharedServices,
    CustomCurrencyPipe,
    { provide: UrlSerializer, useClass: CustomUrlSerializer },
    CookieService,
    provideHttpClient(withInterceptorsFromDi()),
    provideStore(ROOT_REDUCERS, {
      metaReducers,
      // TODO: this needs to be resolved
      // most likely we'll need to change
      // how we define actions/effects/reducers
      runtimeChecks: {
        strictStateImmutability: false,
        strictActionImmutability: false,
      },
    }),
    provideStoreDevtools({
      maxAge: 50,
      logOnly: isDevMode(),
      autoPause: false,
      connectInZone: true,
    }),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
