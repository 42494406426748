import { AlertService } from './alert.service'
import { ConsoleCommandsService } from './console-commands.service'
import { PageTitleService } from './page-title.service'
import { UserService } from './user.service'
import { QueryParamsService } from './query-params.service'
import { ModalService } from './modal-service.service'

import { AuthGuard } from './auth-guard.service'
import { PermissionsGuard } from './permissions-guard.service'
import { NavigatorService } from './navigator.service'
import { NgbDateStringAdapter } from './ngb-date-string.service'
import { NgbTimeStringAdapter } from './ngb-time-string.service'
import { GoogleAuthService } from './google-auth.service'
import { FleetVehicleExcelUploadService } from './fleet-vehicle-excel-upload.service'
import { FleetVehicleCsvUploadService } from './fleet-vehicle-csv-upload.service'
import { CsvService } from './csv.service'
import { ACService } from './access-control.service'
import { FeatureFlagService } from './feature-flag.service'
import { PermissionsResolveGuard } from './permissions-resolve-guard.service'

export const sharedServices = [
  AlertService,
  ConsoleCommandsService,
  PageTitleService,
  UserService,
  QueryParamsService,
  ModalService,
  AuthGuard,
  PermissionsGuard,
  PermissionsResolveGuard,
  NavigatorService,
  NgbDateStringAdapter,
  NgbTimeStringAdapter,
  GoogleAuthService,
  FleetVehicleExcelUploadService,
  FleetVehicleCsvUploadService,
  CsvService,
  ACService,
  FeatureFlagService,
]
